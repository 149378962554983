<template lang="pug">
.payment-result.flex.column.align-center.p-20.gap-20
  .status.text-success.font-size-20(v-if="success") 支付成功
  .status.text-danger.font-size-20(v-else) 支付異常，請回到預約畫面確認狀態。
  .ops
    el-button(type="primary", @click="close") 關閉頁面
</template>

<script>
export default {
  name: 'PaymentReview',
  data() {
    return {
      success: false,
    };
  },
  methods: {
    handleCode() {
      const queryString = window.location.hash.replace('#/payment-result', '');
      const urlParams = new URLSearchParams(queryString);
      // const reserve = urlParams.get('reserve');
      console.log({ result: urlParams.get('result') });
      this.success = (urlParams.get('result') === 'SUCCESS');
    },
    close() {
      window.opener.postMessage('close');
    },
  },
  mounted() {
    this.handleCode();
  },
};
</script>

<style lang="scss">
</style>
